<template>
  <v-row class="mt_dec--40">
    <!-- Start Single Address  -->
    <v-col lg="4" md="4" sm="6" cols="12" class="mt--40" v-if="contactAddress">
      <div class="rn-address">
        <div class="icon">
          <i class="fas fa-phone"></i>
        </div>
        <div class="inner">
          <h4>Teléfono</h4>
          <div v-for="(fono, i) in contactAddress.phoneNumber" :key="i">
            
            <p>{{ fono.country }}:
              <a :href="'tel:' + fono.phoneNumber">{{ fono.phoneNumber }}</a>
            </p> 
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Address  -->

    <!-- Start Single Address  -->
    <v-col lg="4" md="4" sm="6" cols="12" class="mt--40" v-if="contactAddress">
      <div class="rn-address">
        <div class="icon">
          <i class="fas fa-envelope"></i>
        </div>
        <div class="inner">
          <h4>Correo Electrónico</h4>
          <p v-for="(email, i) in contactAddress.email" :key="i">
            <a :href="'mailto:' + email.email">{{ email.email }}</a>
          </p>
        </div>
      </div>
    </v-col>
    <!-- End Single Address  -->

    <!-- Start Single Address  -->
    <v-col lg="4" md="4" sm="6" cols="12" class="mt--40" v-if="contactAddress">
      <div class="rn-address">
        <div class="icon">
          <i class="fas fa-map-marker-alt"></i>
        </div>
        <div class="inner">
          <h4>Ubicación</h4>
          <br>
          <div v-for="(ubicacion, i) in contactAddress.address" :key="i">
            <h5>{{ ubicacion.locationName }}</h5>
            <p>{{ ubicacion.street }}, {{ ubicacion.number }}</p>
            <p>{{ ubicacion.commune }}</p>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Address  -->
  </v-row>
</template>

<script>
export default {
  props: ["contactAddressComponent"],
  data() {
    return {
      contactAddress: null,
    };
  },
  mounted() {
    this.contactAddress = this.contactAddressComponent;
    console.log(this.contactAddress);
  },
};
</script>
