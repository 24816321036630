<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="success" width="500">
      <v-card>
        <v-card-title style="color: white" class="text-h5 green">
          Mensaje enviado con éxito
        </v-card-title>

        <v-card-text>
          {{ formData.name }} su mensaje ha sido con éxito.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="success = false">
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="error" width="500">
      <v-card>
        <v-card-title style="color: white" class="text-h5 green">
          Su mensaje no ha podido ser enviado
        </v-card-title>

        <v-card-text>
          {{ formData.name }} su mensaje no pudo ser enviado.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="error = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row align="start" class="row--35">
      <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1">
        <div class="section-title text-left mb--50 mb_sm--30 mb_md--30">
          <h2 class="heading-title">...o directamente</h2>
          <p class="description">
            {{ contactDescriptionComponent }}
          </p>
        </div>
        <div class="form-wrapper">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ errors }"
              >
                <label>
                  <input
                    type="text"
                    v-model="formData.name"
                    placeholder="Nombre *"
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </label>
              </ValidationProvider>

              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <label>
                  <input
                    type="text"
                    rules="required|email"
                    v-model="formData.email"
                    placeholder="Email *"
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </label>
              </ValidationProvider>

              <ValidationProvider
                name="subject"
                rules="required"
                v-slot="{ errors }"
              >
                <label>
                  <input
                    type="text"
                    v-model="formData.subject"
                    placeholder="Tema"
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </label>
              </ValidationProvider>

              <ValidationProvider
                name="message"
                rules="required"
                v-slot="{ errors }"
              >
                <label>
                  <textarea
                    v-model="formData.message"
                    placeholder="Mensaje"
                  ></textarea>
                  <span class="inpur-error">{{ errors[0] }}</span>
                </label>
              </ValidationProvider>

              <button
                class="rn-button-style--2 btn_solid"
                type="submit"
                value="submit"
              >
                Enviar
              </button>
            </form>
          </ValidationObserver>
        </div>
      </v-col>
      <v-col lg="6" md="6" sm="12" cols="12" order="1" order-md="2">
        <div class="thumbnail mb_md--40 mb_sm--40">
          <img :src="contactImageComponent.url" alt="contactos inflow" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import axios from "axios";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: ["contactDescriptionComponent", "contactImageComponent"],
  data() {
    return {
      overlay: false,
      success: false,
      error: false,
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
    };
  },
  methods: {
    async onSubmit() {
      this.overlay = true;
      try {
        const res = await axios.post(`${this.$apiUrl}/api/contact-messages`, {
          data: {
            name: this.formData.name,
            subject: this.formData.subject,
            email: this.formData.email,
            message: this.formData.message,
          },
        });
        this.success = true;
        this.overlay = false;
        console.log(res);
      } catch (err) {
        console.log(err);
        this.overlay = false;
        this.error = true;
      }
      console.log(this.formData);
    },
  },
};
</script>
