<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="data">
      <!-- Start Header Area -->
      <Header>
        <img
          slot="logo"
          src="../assets/images/logo/Logo-Inflow-Hor-BB@2x.png"
          style="max-width: 80%; max-height: 80%"
        />
      </Header>
      <!-- End Header Area -->

      <!-- Start Breadcrump Area  -->
      <div
        class="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"
        data-black-overlay="5"
        :style="{
          backgroundImage:
            'url(' +
            data.heroBackgroundImage.data.attributes.formats.large.url +
            ')',
        }"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="rn-page-title text-center pt--30">
                <h2 class="heading-title theme-gradient">Contactanos</h2>
                <p>
                  {{ data.heroDescription }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Breadcrump Area  -->

      <!-- Start Contact Address Area  -->
      <div class="rn-contact-address-area rn-section-gap bg_color--5">
        <v-container>
          <ContactAddress :contactAddressComponent="data.contactInfo" />
        </v-container>
      </div>
      <!-- End Contact Address Area  -->
        
      <!-- Start Contact Area  -->
      <div class="rn-contact-area rn-section-gap bg_color--1">
        <div class="contact-form--1">
          <v-container>
            <Contact
              :contactDescriptionComponent="data.bodyDescription"
              :contactImageComponent="data.bodyImage.data.attributes"
            />
          </v-container>
        </div>
      </div>
      <!-- End Contact Area  -->


      <!-- Start Brand Area -->
      <!-- <div class="rn-brand-area brand-separation rn-section-gap bg_color--5">
        <v-container v-if="data.clientes">
          <Brand :brandComponent="data.clientes" />
        </v-container>
      </div> -->
      <!-- End Brand Area -->
      <Footer />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/header/Header";
import ContactAddress from "../components/contact/ContactAddress";
import Contact from "../components/contact/Contact";
import Brand from "../components/brand/Brand";
import Footer from "../components/footer/Footer";
export default {
  components: { Header, ContactAddress, Contact, Brand, Footer },
  data() {
    return {
      data: null,
      overlay: false,
    };
  },
  mounted() {
    this.getContact();
  },
  methods: {
    async getContact() {
      try {
        const r = await axios(
          `${this.$apiUrl}/api/contact-single?populate[0]=heroBackgroundImage,bodyImage&populate[1]=contactInfo.email,contactInfo.phoneNumber&populate[2]=contactInfo.address.*`
        );
        this.data = r.data.data.attributes;
        this.overlay = false;
      } catch (err) {
        console.log(err);
        this.overlay = false;
      }
    },
  },
};
</script>

<style></style>
